import Client from "./client";
import Storage from "./storage";
const storage = new Storage();
export default class Workgroup extends Client{
    constructor(){
        super();
    }
    listingFiles(owner_id,workgroup_id,limit,offset,keyword,id,deleted){
        return new Promise(function(resolve,reject){
            this.get(`workgroups/data?limit=${limit}&offset=${offset}&keyword=${keyword}&parent_id=${id}&deleted=${deleted}&owner_id=${owner_id}&workgroup_id=${workgroup_id}`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = storage.filter(result.data);
                    resolve({data:resultData,totalLoad:resultData.length,totalData:result.recordsTotal,breadcumbs:result.breadcumbs});
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/add`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    listing(limit,offset){
        return new Promise(function(resolve,reject){
            this.get(`workgroups/?limit=${limit}&offset=${offset}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    retrieve(id){
        return new Promise(function(resolve,reject){
            this.get(`workgroups/retrieve/${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    resendInvitation(data){
        return new Promise(function(resolve,reject){
            this.post(`workgroups/resend_invite`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    removeParticipant(data){
        return new Promise(function(resolve,reject){
            this.delete(`workgroups/participant`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    joinWorkgroup(data){
        return new Promise(function(resolve,reject){
            this.post('workgroups/join_workgroup',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else if(result.status == 204){
                    reject(result.data)
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                reject(err.response)
            })
        }.bind(this))
    }
    beforeUpload(data){
        return new Promise(function(resolve,reject){
            this.post('workgroups/before_upload',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else if(result.status == 204){
                    reject(result.data)
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                reject(err.response)
            })
        }.bind(this))
    }
    upload(data){
        return new Promise(function(resolve,reject){
            this.postFormdata('workgroups/upload_files',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else if(result.status == 204){
                    reject(result.data)
                }else{
                    reject(result.data)
                }
            })
            .catch(err=>{
                reject(err)
            });
        }.bind(this))
    }
    uploadFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/upload_folder`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createFile(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/create_files`,data)
            .then((result)=>{
                if(result.status == 200){
                    const data = result.data[0];
                    const docType = storage.documentType(data.extension);
                    if(docType != "nothing"){
                        const user = JSON.parse(localStorage.getItem('user'))[0];
                        const fullhost = location.protocol + '//' + location.host;
                        window.open(`${fullhost}/open.html?dir=${data.directory}&hash=${data.version}&title=${data.file_name}&filetype=${data.extension}&link=${data.url}&id=${user._id}&name=${user.firstname}&type=${docType}`,"_blank");
                    }
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err.response)
            }
                );
        }.bind(this))
    }
    newFolder(data){
        return new Promise(function(resolve,reject){
            this.post(`workgroups/mkdir`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    sendFileToChat(data){
        return new Promise(function(resolve,reject){
            this.post(`workgroups/send_file_to_chat`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message);
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    copy(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(`workgroups/copy_file`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message);
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    copyFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/copy_folder`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    copyToPersonal(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/send_to_personal`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>{
                reject(err)
            });
        }.bind(this))
    }
    copyToWorkgroup(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/send_to_workgroup`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>{
                reject(err.response)
            });
        }.bind(this))
    }
    fileRetrieve(data){
        return new Promise(function(resolve,reject){
            this.get(`workgroups/retrieve_data?id=${data.id}&owner_id=${data.owner_id}`)
            .then((result) => {
                if(result.status == 200){
                    var resultData = storage.filter([result.data]);
                    resolve(resultData[0]);
                }else{
                    reject(result.display_message)
                }
            })
            .catch((err) => {
                reject(err)
            })
        }.bind(this))
    }
    deleteFile(id,owner_id,workgroup_id){
        return new Promise(function(resolve,reject){
            this.hapus(`workgroups/remove_data?id=${id}&owner_id=${owner_id}&workgroup_id=${workgroup_id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                if(err.status == 500){
                    err.data.display_message = 'Ops something went wrong, try again later'
                }
                reject(err)
            });
        }.bind(this))
    }
    move(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(`workgroups/move_file`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    moveFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`workgroups/move_folder`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    leaveWorkgroup(payload){
        return new Promise(function(resolve,reject){
            this.post('workgroups/leave_workgroup', payload)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    deleteForever(id){
        return new Promise(function(resolve,reject){
            this.hapus(`workgroups/delete/?workgroup_id=${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    rename(data){
        return new Promise(function(resolve,reject){
            this.put(`workgroups/rename`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getRecent(owner_id,workgroup_id){
        return new Promise(function(resolve,reject){
            this.get(`workgroups/recent_files/?limit=100&offset=0&owner_id=${owner_id}&workgroup_id=${workgroup_id}`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = storage.filter(result.data);
                    resolve(resultData);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    updateWorkgroup(data){
        return new Promise(function(resolve,reject){
            this.post(`workgroups/update`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    changeAccess(data){
        return new Promise(function(resolve,reject){
            this.put(`workgroups/change_access`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    // getRecent(owner_id,workgroup_id){
    //     return new Promise(function(resolve,reject){
    //         this.get(`workgroups/recent_files?limit=100&owner_id=${owner_id}&`)
    //         .then((result)=>{
    //             if(result.status == 200){
    //                 var resultData = this.filter(result.data);
    //                 resolve(resultData);
    //             }else{
    //                 reject(result.display_message)
    //             }
    //         })
    //         .catch(err=>reject(err));
    //     }.bind(this))
    // }
}