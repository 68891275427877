// import axios from 'axios'

// export default axios.create({
//   baseURL: 'http://117.54.166.50:8888'
// })

import Client from "./client"
export default class Package extends Client{
  constructor() {
      super();
  }
  fetchPackages(user_id){
      return new Promise(function(resolve,reject){
          this.globalGet(`packages/lists?user_id=${user_id}`)
          .then((result)=>{
              if(result.status == 200){
                  resolve(result.data);
              }else{
                  reject(result.display_message)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
  }
  fetchPackageById(id){
    return new Promise(function(resolve,reject){
        this.globalGet(`packages/package?id=${id}`)
        .then((result)=>{
            if(result.status == 200){
                resolve(result.data)
            }else{
                reject(result.display_message)
            }
        })
        .catch(err=> {
            reject(err)
        });
    }.bind(this))
  }
  fetchCheckout(payload){
    return new Promise(function(resolve,reject){
        this.post('checkout', payload)
        .then((result)=>{
            if(result.status == 200){
                resolve(result.data)
                window.open(result.data.url, '_self')
            }else{
                reject(result.display_message)
            }
        })
        .catch(err=>reject(err));
    }.bind(this))
  }
  fetchPaymentHistory(){
    return new Promise(function(resolve,reject){
        this.get('user/payments/history')
        .then((result)=>{
            if(result.status == 200){
                resolve(result.data)
            }else{
                reject(result.display_message)
            }
        })
        .catch(err=>reject(err));
    }.bind(this))
  }
  setPaymentMethod(){
    return new Promise(function(resolve,reject){
        this.get('user/getPortalLink')
        .then((result)=>{
            if(result.status == 200){
                window.open(result.data.url, '_self')
                resolve(result.data)
            }else{
                reject(result.display_message)
            }
        })
        .catch(err=> {
            reject(err)
        });
    }.bind(this))
  }
}