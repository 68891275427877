
import User from "./../../api/users";
const user = new User();
export const state = {
    firstName : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].firstname : "",
    lastName :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].lastname : "",
    email :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].email : "",
    date_join :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].date_join : "",
    size_directory :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].size_directory : "",
    total_used :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].total_used_directory : "",
    id :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0]._id : "",
    directory :  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].directory : "",
    loading : false,
    used_directory_string : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].total_used_directory_string : "",
    total_directory_string : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].total_directory_string : "",
    package : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].package : "",
    cardPayment : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].card : "",
    enabled2fa : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].enabled2fa : ""
}

export const mutations = {
    SET_ENABLED_2FA(state, newValue) {
        state.enabled2fa = newValue
    },
    SET_USED_DIRECTORY_STRING(state, newValue) {
        state.used_directory_string = newValue
    },
    SET_TOTAL_DIRECTORY_STRING(state, newValue) {
        state.total_directory_string = newValue
    },
    SET_FIRST_NAME(state, newValue) {
        state.firstName = newValue
    },
    SET_LAST_NAME(state, newValue) {
        state.lastName = newValue
    },
    SET_EMAIL(state, newValue) {
        state.email = newValue
    },
    SET_DATE_JOIN(state, newValue) {
        state.date_join = newValue
    },
    SET_SIZE(state, newValue) {
        state.size_directory = newValue
    },
    SET_USED(state, newValue) {
        state.total_used = newValue
    },
    SET_ID(state, newValue) {
        state.id = newValue
    },
    SET_DIRECTORY(state, newValue) {
        state.directory = newValue
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_PACKAGE(state, newValue) {
        state.package = newValue;
    },
    SET_CARD_PAYMENT(state, newValue) {
        state.cardPayment = newValue
    }
}

export const getters = {
    enabled2fa(state) {
        return state.enabled2fa
    },
    used_directory_string(state) {
        return state.used_directory_string
    },
    total_directory_string(state) {
        return state.total_directory_string
    },
    firstName(state) {
        return state.firstName
    },
    lastName(state) {
        return state.lastName
    },
    email(state) {
        return state.email
    },
    date_join(state) {
        return state.date_join
    },
    size_directory(state) {
        return state.size_directory
    },
    total_used(state) {
        return state.total_used
    },
    id(state) {
        return state.id
    },
    directory(state) {
        return state.directory
    },
    fullname(state){
        return state.firstName +" "+ state.lastName
    },
    loading(state) {
        return state.loading
    },
    package(state) {
        return state.package
    },
    card_payment(state) {
        return state.cardPayment
    }
}


export const actions = {
    // Logs in the current user.
    getSocket(context) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        var id = context.getters["id"]
        return new Promise((resolve, reject) => {
            user.newSocket(id)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",result,{root:true});
                context.commit("error/SET_SUCCESS",true,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    getLimit(context) {
        const { commit} = context;
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.userLimit()
            .then((result)=>{
                commit("SET_FIRST_NAME",result[0].firstname);
                commit("SET_LAST_NAME",result[0].lastname);
                commit("SET_EMAIL",result[0].email);
                commit("SET_DATE_JOIN",result[0].date_join);
                commit("SET_SIZE",result[0].size_directory);
                commit("SET_USED",result[0].total_used_directory);
                commit("SET_ID",result[0]._id);
                commit("SET_USED_DIRECTORY_STRING",result[0].total_used_directory_string)
                commit("SET_TOTAL_DIRECTORY_STRING",result[0].size_directory_string)
                commit("SET_DIRECTORY",result[0].directory);
                commit("SET_PACKAGE",result[0].package);
                commit("SET_CARD_PAYMENT",result[0].card);
                commit("SET_ENABLED_2FA",result[0].enabled2fa)
                resolve(result);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    editPassword(context,item) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.editPassword(item)
            .then((result)=>{
                commit("SET_LOADING",false);
                context.dispatch("getLimit",null);
                context.commit("error/SET_MESSAGE",result,{root:true});
                context.commit("error/SET_SUCCESS",true,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },

    PasswordEdit(context,item) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.PasswordEdit(item)
            .then((result)=>{
                commit("SET_LOADING",false);
                context.dispatch("getLimit",null);
                context.commit("error/SET_MESSAGE",result,{root:true});
                context.commit("error/SET_SUCCESS",true,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    deleteAccount(context,password) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.deleteAccount(password)
            .then((result)=>{
                commit('SET_CURRENT_USER', null)
                localStorage.removeItem("user")
                localStorage.clear()
                window.location.reload()
                resolve(result)
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    cancelPlan(context){
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.cancelCurrentPlan()
            .then((res) => {
                commit("SET_LOADING",false);
                resolve(res)
            })
            .catch((err) => {
                commit("SET_LOADING",false);
                reject(err)
            })
        })
    },
}