import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted';
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from '@/state/store'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueAnalytics from 'vue-analytics'

import firebase from "firebase/app";
import "firebase/messaging";

import browserDetect from "vue-browser-detect-plugin";

import axios from 'axios'

axios.get(`${process.env.VUE_APP_API}setting`)
  .then((res) => {
    const result = res.data.data 
    Vue.prototype.$logo = result.logo

    Sentry.init({
      Vue,
      dsn: "https://f6a74f94681545ab8d9100552ef5d077@sentry.desktopip.xyz/8",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost",location.protocol + '//' + location.host, /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    
    Vue.use(VueAnalytics, {
      id: 'UA-216365795-1'
    })
    Vue.use(browserDetect);
    Vue.use(Vuelidate)
    Vue.use(VueRouter)
    Vue.use(Toasted)
    Vue.use(vco)
    Vue.config.productionTip = false
    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)
    Vue.use(VueMask)
    Vue.use(VueSweetalert2);
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
    
    
    firebase.initializeApp({
      apiKey: "AIzaSyCLzjBy8R3fSiqBPkqDmWq_CHUB1aTdoXE",
      authDomain: "jaybod-notif.firebaseapp.com",
      projectId: "jaybod-notif",
      storageBucket: "jaybod-notif.appspot.com",
      messagingSenderId: "4313384687",
      appId: "1:4313384687:web:dc44cd2aeffb42b0b0fd1a",
      measurementId: "G-LGRXZRZT0M"
    });
    
    navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then((registration) => {
      const messaging = firebase.messaging();
      messaging.useServiceWorker(registration);
      registration.update()
    })
    .catch((err) => {
    });
      // fiebase configs end
  })
