import Package from '../../api/packagesAPI'
const packages = new Package()

export const state = {
    packages: [],
    packageId: null,
    loading: false,
    selectedPrice: false, // false = month true = year
    checkout: {},
    paymentHistory: []
}

export const mutations = {
    SET_PACKAGES (state, payload) {
        state.packages = payload
    },
    SET_PACKAGE_BY_ID (state, payload) {
        state.packageId = payload
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_SELECTED_PRICE(state, payload) {
        state.selectedPrice = payload
    },
    SET_CHECKOUT(state, payload) {
        state.checkout = payload
    },
    SET_PAYMENT_HISTORY(state, payload) {
        state.paymentHistory = payload
    }
}

export const actions = {
    getPackages (context) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            // let user_id = context.rootGetters["user/id"] //old-version
            let user = context.rootGetters["auth/user"]
            let user_id = ''
            if(user == null){
                user_id = ''
            } else {
                user_id = user[0]._id
            }
            user_id = context.rootGetters["user/id"]
            packages.fetchPackages(user_id)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                let newPackages = result.map(e => {
                    return {
                        showMore: false,
                        ...e
                    }
                })
                context.commit("SET_PACKAGES", newPackages);
                resolve(newPackages)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getPackageById (context, id) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            packages.fetchPackageById(id)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.commit("SET_PACKAGE_BY_ID", result);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getCheckout (context, payload) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        const fullhost = location.protocol + '//' + location.host;
        payload.callback_success = `${fullhost}/payment/success`
        payload.callback_cancel = `${fullhost}/payment/failed`
        return new Promise((resolve, reject) => {
            packages.fetchCheckout(payload)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.commit("SET_CHECKOUT", result);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getPaymentHistory (context) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            packages.fetchPaymentHistory()
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.commit("SET_PAYMENT_HISTORY", result);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getPaymentMethod(context) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            packages.setPaymentMethod()
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.commit("SET_CHECKOUT", result);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
        
    }
}

export const getters = {
    allPackages(state) {
        return state.packages
    },
    packageById(state) {
        return state.packageId
    },
    selectedPrice(state) {
        return state.selectedPrice
    },
    checkout(state) {
        return state.checkout
    },
    paymentHistory(state) {
        return state.paymentHistory
    }
}