// @ts-nocheck
import User from "./../../api/users";
const user = new User();
export const state = {
    currentUser: localStorage.getItem('user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    user(state){
        return state.currentUser;
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },
    
    validateHash(context,hash) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            user.validateHash(hash)
            .then((result)=>{
                context.commit('SET_CURRENT_USER', result)
                context.commit("user/SET_FIRST_NAME",result[0].firstname,{ root: true })
                context.commit("user/SET_LAST_NAME",result[0].lastname,{ root: true })
                context.commit("user/SET_EMAIL",result[0].email,{ root: true })
                context.commit("user/SET_DATE_JOIN",result[0].date_join,{ root: true })
                context.commit("user/SET_SIZE",result[0].size_directory,{ root: true })
                context.commit("user/SET_USED",result[0].total_used_directory,{ root: true })
                context.commit("user/SET_ID",result[0]._id,{ root: true })
                context.commit("user/SET_PACKAGE",result[0].package,{ root: true })
                resolve(result)
            })
            .catch(err=>reject(err))
        })
    },

    // Logs in the current user.
    logIn(context, { email, password } = {}) {
        const { commit, dispatch, getters } = context;
        if (getters.loggedIn) return dispatch('validate')
        return new Promise((resolve, reject) => {
            user.login(email,password)
            .then((result)=>{
                commit('SET_CURRENT_USER', result);
                context.commit("user/SET_FIRST_NAME",result[0].firstname,{ root: true });
                context.commit("user/SET_LAST_NAME",result[0].lastname,{ root: true });
                context.commit("user/SET_EMAIL",result[0].email,{ root: true });
                context.commit("user/SET_DATE_JOIN",result[0].date_join,{ root: true });
                context.commit("user/SET_SIZE",result[0].size_directory,{ root: true });
                context.commit("user/SET_USED",result[0].total_used_directory,{ root: true });
                context.commit("user/SET_ID",result[0]._id,{ root: true });
                context.commit("user/SET_PACKAGE",result[0].package,{ root: true })
                resolve(result);
            })
            .catch(err=>reject(err));
        });
    },

    // Logs out the current user.
    logOut({ commit, dispatch }) {
        // eslint-disable-next-line no-unused-vars
        // dispatch("chat/logout",null,{root:true});
        commit('SET_CURRENT_USER', null)
        // localStorage.removeItem("user")
        // window.localStorage.removeItem("vuex")
        window.localStorage.clear()
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    register({ commit, dispatch, getters }, data = {}) {
        return new Promise((resolve,reject) => {
            user.register(JSON.stringify(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err=>reject(err)))
        })
    },

    // verify code registrations
    // eslint-disable-next-line no-unused-vars
    verify({ commit, dispatch, getters }, data = {}) {
        return new Promise((resolve,reject) => {
            user.verifyCode(JSON.stringify(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err=>reject(err)))
        })
    },

    

    // send email forgot password
    // eslint-disable-next-line no-unused-vars
    forgotPassword({ commit, dispatch, getters }, data = {}) {
        return new Promise((resolve,reject) => {
            user.forgotPassword(JSON.stringify(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err=>reject(err)))
        })
    },
    
    // send email resend coded
    // eslint-disable-next-line no-unused-vars
    resendCode({ commit, dispatch, getters }, data = {}) {
        return new Promise((resolve,reject) => {
            user.resendCode(JSON.stringify(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err=>reject(err)))
        })
    },
    
    
    // send email resend coded
    // eslint-disable-next-line no-unused-vars
    changePassword({ commit, dispatch, getters }, data = {}) {
        return new Promise((resolve,reject) => {
            user.changePassword(JSON.stringify(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err=>reject(err)))
        })
    },
    

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        // eslint-disable-next-line no-unused-vars
       return state.currentUser
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    localStorage.setItem(key, JSON.stringify(state))
}
