import Firebase from '../../api/firebase'

const firebase = new Firebase()

export const state = {
    loading: false,
    notifFirebase: null
}
export const mutations = {
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_NOTIF_FIREBASE(state,payload){
        state.notifFirebase = payload
    }
}
export const actions = {
    storeFirebase(context, token){
            context.commit("SET_LOADING",true);
            context.commit("error/SET_MESSAGE","",{root:true});
            context.commit("error/SET_ERROR",false,{root:true});
            context.commit("error/SET_SUCCESS",false,{root:true});
            return new Promise((resolve, reject) => {
                let user_id = context.rootGetters["user/id"]
                firebase.storeFirebaseToken(user_id,token)
                .then((result)=>{
                    context.commit("SET_LOADING",false);
                    resolve(result)
                })
                .catch((err)=>{
                    context.commit("error/SET_MESSAGE",err,{root:true});
                    context.commit("error/SET_ERROR",true,{root:true});
                    reject(err);
                })
            })
    },
    setNotifFirebase(context,data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});

        // var newData = JSON.parse(data.data)
        var newData = data

        if(newData.datafile != null){
            var encryptDecrypt = newData.datafile.encrypt.code
            context.commit("SET_NOTIF_FIREBASE", data)
            context.dispatch("notif/notifSecure", newData,{root:true})
            if(encryptDecrypt == 1 || encryptDecrypt == 2 || encryptDecrypt == 3){
                context.commit("encrypt/UPDATE_PROGRESS",newData,{root:true})
            } else{
                context.commit("decrypt/UPDATE_PROGRESS",newData,{root:true})
            }
        } else if(newData.dataworkgroup != null){
            context.dispatch("notif/notifWgInfo", newData,{root:true})
        }
    }
}
export const getters = {
    notifFirebase(state){
        return state.notifFirebase
    }
}