
import Share from "./../../api/share";
const share = new Share();
import Storage from "./../../api/storage";
const storage = new Storage();
export const state = {
    item : null,
    items : [],
    loading : false,
    limit : "",
    offset : 0,
    keyword : "",
    showFile : false,
    shared : null,
    showProperties : false
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_ITEM(state, newValue) {
        state.item = newValue;
    },
    SET_SHARED(state, newValue) {
        state.shared = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_LIMIT(state, newValue) {
        state.limit = newValue;
    },
    SET_OFFSET(state, newValue) {
        state.offset = newValue;
    },
    SET_KEYWORD(state, newValue) {
        state.keyword = newValue;
    },
    SET_SHOWFILE(state, newValue) {
        state.showFile = newValue;
    },
    SET_SHOW_PROPERTIES(state,value){
        state.showProperties = value;
    }
    
}

export const getters = {
    items(state) {
        return state.items
    },
    item(state) {
        return state.item
    },
    loading(state) {
        return state.loading
    },
    limit(state) {
        return state.limit
    },
    offset(state) {
        return state.offset
    },
    keyword(state) {
        return state.keyword
    },
    showFile(state) {
        return state.showFile
    },
    shared(state) {
        return state.shared
    },
    showProperties(state){
        return state.showProperties
    }
}


export const actions = {
    // Logs in the current user.
    // eslint-disable-next-line no-unused-vars
    edit_access(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            share.editAccess(data)
            .then((result)=>{
                resolve(result);
                context.dispatch("storage/fileretrieve",{_id : data.file_id,directory:data.directory},{root:true});
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    remove_user(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            share.remove_user(data)
            .then((result)=>{
                resolve(result);
                context.dispatch("storage/fileretrieve",{_id : data.file_id,directory:data.directory},{root:true});
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    add_user(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        context.commit("SET_LOADING",true);
        return new Promise((resolve, reject) => {
            share.add_user(data)
            .then((result)=>{
                resolve(result);
                context.dispatch("storage/fileretrieve",{_id : data.files_id,directory:data.directory},{root:true});
                context.commit("SET_LOADING",false);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                context.commit("SET_LOADING",false);
                reject(err);
            });
        });
    },
    public_share(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        context.commit("SET_LOADING",true);
        return new Promise((resolve, reject) => {
            share.public_share(data)
            .then((result)=>{
                resolve(result);
                context.dispatch("storage/fileretrieve",{_id : data.files_id,directory:data.directory},{root:true});
                context.commit("SET_LOADING",false);
            })
            .catch((err)=>{
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                context.commit("SET_LOADING",false);
                reject(err);
            });
        });
    },
    disable_public_share(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        context.commit("SET_LOADING",true);
        return new Promise((resolve, reject) => {
            share.disable_public_share(data)
            .then((result)=>{
                resolve(result);
                context.dispatch("storage/fileretrieve",{_id : data.files_id,directory:data.directory},{root:true});
                context.commit("SET_LOADING",false);
            })
            .catch((err)=>{
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                context.commit("SET_LOADING",false);
                reject(err);
            });
        });
    },
    listing(context) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const { commit, getters } = context;
        commit("SET_LOADING",true);
        return new Promise((resolve, reject) => {
            share.listing(
                getters["limit"],
                getters["offset"],
                getters["keyword"]
            )
            .then((result)=>{
                result = result.map((v)=>{
                    var filtered = storage.filter(v.file);
                    filtered = filtered.map((o)=>{
                        return {
                            ...o,
                            directory : v.directory
                        }
                    })
                    return {
                        _id : v._id,
                        files_id : v.files_id,
                        users_id : v.users_id,
                        directory : v.directory,
                        datecreated : v.datecreated,
                        file : filtered,
                        users : v.users
                    }
                });
                result = result.filter((v)=>v.file.length > 0);
                if(urlParams.get('key') != null){
                    var myfile = result.find((v)=>v.files_id == urlParams.get('key'));
                    if(myfile){
                        context.commit("storage/SET_ITEM",myfile.file[0],{root:true});
                        context.dispatch("storage/fileretrieve",{_id : myfile.file[0]._id,directory:myfile.file[0].directory},{root:true});
                        commit("SET_SHARED",myfile);
                        commit("SET_SHOWFILE",true);
                    }
                }
                commit('SET_ITEMS', result);
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit('SET_ITEMS', []);
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
}