import { SocketAPI } from "../../api/socketio"
import moment from 'moment'

export const state = {
    loading : false,
    participants : [],
    chats : [],
    messageUser : [],
    groups : null,
    userChat : [],
    histories : [],
    chatByDay : null,
    listgroups : [],
    notif : [],
    notifChatGroup : [],
    notifChatAndEncrypt : [],
    filesChat : null,
    deleteMsg : null
}

export const mutations = {
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    PARTICIPANT_WORKGROUP(state,payload){
        state.participants.push(payload)
    },
    SET_CHAT(state,payload){
        // state.chats.push(payload)
        state.chats = payload
        // state.messageUser.push(payload)
    },
    SET_USER_CHAT(state,payload){
        var index = state.messageUser.findIndex((v) => {
            return v.time == payload.time
        })

        if(index < 0){
            state.messageUser.push(payload)
        }
    },
    SET_GROUP(state,payload){
        state.groups = payload
    },
    SET_SORTING_PARTICIPANT(state,payload){
        state.userChat = payload
    },
    RESET_CHAT(state,payload){
        state.messageUser = payload
    },
    RESET_PARTICIPANT(state,payload){
        state.userChat = payload
    },
    SET_HISTORY_CHAT(state,payload){
        state.hitories = payload
    },
    SET_CHAT_PER_DAY(state,payload){
        state.chatByDay = payload
    },
    SET_LIST_GROUP(state,payload){
        var exists = state.listgroups.findIndex((v)=>{
            return v.roomId == payload.roomId
        })
        if(exists >=0){
            for (let index = 0; index < payload.users.length; index++) {
             var userexists = state.listgroups[exists].users.findIndex((u)=>{
                 return u.user_id == payload.users[index].user_id
             })   
             if(userexists >=0){
                var socketexists = state.listgroups[exists].users.findIndex((u)=>{
                    return u.socket_id == payload.users[index].socket_id
                })
                if(socketexists < 0){
                    state.listgroups[exists].users[userexists].socket_id = payload.users[index].socket_id
                }
             }else{
                state.listgroups[exists].users.push(payload.users[index])
             }
            }
        }else{
            state.listgroups.push(payload)
        }
    },
    USER_OFFLINE(state,payload){
        var exists = state.listgroups.findIndex((v)=>{
            return v.roomId == payload.group
        })
        if(exists >= 0){
            state.listgroups[exists].users = state.listgroups[exists].users.filter(v=>v.user_id != payload.user.user_id)
        }
    },
    SET_NOTIF(state,payload){
        state.notif.push(payload)
    },
    SET_NOTIF_CHAT(state,payload){
        state.notifChatGroup = payload
    },
    SET_NEW_NOTIF(state,payload){
        state.notif = payload
    },
    SET_NEW_CHAT_ENCRYPT(state,payload){
        state.notifChatAndEncrypt = payload
    },
    SET_CHAT_AND_ENCRYPT(state,payload){
        if(payload.group_by == null){
            state.notifChatAndEncrypt.push(payload)
        } else{
            var exists = state.notifChatAndEncrypt.findIndex((v)=>{
                return v.group_by == payload.group_by
            })
            if(exists >= 0){
                state.notifChatAndEncrypt[exists].data = [payload.data[0],...state.notifChatAndEncrypt[exists].data]
                state.notifChatAndEncrypt[exists].time = payload.data[0].time
                state.notifChatAndEncrypt[exists].message = payload.data[0].message
            } else {
                state.notifChatAndEncrypt.push(payload)
            }
            state.notifChatAndEncrypt = state.notifChatAndEncrypt.sort(function(a,b){return b.data[0].time - a.data[0].time})
        }
        state.notifChatAndEncrypt = state.notifChatAndEncrypt.sort(function(a,b){return b.time - a.time})
    },
    SET_NEW_NOTIF_ENCRYPT(state,payload){
        var notifED = state.notifChatAndEncrypt
        var indexFile = notifED.findIndex((v) => {
            return v.data._id == payload.data._id
        })
        if(indexFile >= 0){
            var newNotif = notifED.filter(item => item.data._id != payload.data._id)
            state.notifChatAndEncrypt = newNotif
        }
    },
    SET_MEDIAS_CHAT(state, payload){
        state.filesChat = payload
    },
    REMOVE_MEDIAS_CHAT(state, value){
        state.filesChat = value
    },
    SET_DELETE_MESSAGE(state, data){
        state.deleteMsg = data
    }
}

export const actions = {
    isConnectedSocket(context){
        return SocketAPI.isConnected()
    },
    openChat(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        let user_id = context.rootGetters["user/id"]
        let workgroup = context.rootGetters["workgroup/workgroup"]
        SocketAPI.open(user_id,workgroup._id)
        context.commit("SET_LOADING",false);
    },
    setReadNotif(context,data){
        SocketAPI.readNotif(data)
    },
    setList(contex,data){
        contex.commit('SET_LIST_GROUP', data)
        contex.dispatch('sortingParticipant')
    },
    sortingParticipant(contex){
        let listgroups = contex.getters['listgroups'];
        let workgroup = contex.rootGetters['workgroup/workgroup'];
        if(workgroup != null){
            var indexGroup = listgroups.findIndex((v)=>v.roomId == workgroup._id)
            workgroup.participant = workgroup.participant.map((wr)=>{
                var newDataUser = {
                        access_type: wr.access_type,
                        email: wr.email,
                        firts_name:wr.firts_name,
                        lastname: wr.lastname,
                        _id: wr._id,
                        status: wr.status
                }
                var useronline = (typeof listgroups[indexGroup] == 'undefined') ? 0 : listgroups[indexGroup].users.findIndex(v=>v.user_id == wr._id)
                newDataUser.online = useronline >= 0;
                return newDataUser
            })
            var newDataUserOwner = {
                access_type: "owner",
                email: workgroup.email,
                firts_name:workgroup.name,
                lastname: "",
                _id: workgroup.owner_id
            }
            var useronlineowner = (typeof listgroups[indexGroup] == 'undefined') ? 0 : listgroups[indexGroup].users.findIndex(v=>v.user_id == workgroup.owner_id)
            newDataUserOwner.online = useronlineowner >= 0;
            var ownerIndex = workgroup.participant.findIndex(v=>v._id==newDataUserOwner._id)
            if(ownerIndex < 0){
                workgroup.participant.push(newDataUserOwner)
            }else{
                workgroup.participant[ownerIndex].online = useronlineowner >= 0
            }
            contex.commit("workgroup/SET_WORKGROUP",workgroup,{root:true});
        }
    },
    sortParticipantOffline(context,data){
        context.commit("USER_OFFLINE",data)
        context.dispatch('sortingParticipant')
        // let workgroup = context.getters["sortParticipant"]
        // // var userWorkgroup = workgroup.participant
        // context.commit('SET_SORTING_PARTICIPANT',workgroup.map((v) => {
        //     v.online = v._id != data.user_id
        //     return v
        // }))
    },
    setNewMedia(context,data){
        var medias = context.getters["filesChat"]
        var extensionFiles = [
            "doc", "docm", "docx", "dot", "dotm", "dotx", "epub",
            "fodt", "fb2", "htm", "html", "mht", "odt", "ott",
            "pdf", "rtf", "txt", "djvu", "xml", "xps", "csv",
            "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt",
            "xltm", "xltx", "fodp", "odp", "otp", "pot", "potm",
            "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx"
        ]
        var extensionMedia = [
            "ase", "art", "bmp", "blp", "cd5", "cit", "cpt", "cr2", "cut", "dds",
            "dib", "djvu", "egt", "exif", "gif", "gpl", "grf", "icns", "ico", "iff",
            "jng", "jpeg", "jpg", "jfif", "jp2", "jps", "lbm", "max", "miff", "mng",
            "msp", "nitf", "ota", "pbm", "pc1", "pc2", "pc3", "pcf", "pcx", "pdn",
            "pgm", "PI1", "PI2", "PI3", "pict", "pct", "pnm", "pns", "ppm", "psb",
            "psd", "pdd", "psp", "px", "pxm", "pxr", "qfx", "raw", "rle", "sct",
            "sgi", "rgb", "int", "bw", "tga", "tiff", "tif", "vtf", "xbm", "xcf",
            "xpm", "3dv", "amf", "ai", "awg", "cgm", "cdr", "cmx", "dxf", "e2d",
            "egt", "eps", "fs", "gbr", "odg", "svg", "stl", "vrml", "x3d", "sxd",
            "v2d", "vnd", "wmf", "emf", "art", "xar", "png", "webp", "jxr", "hdp",
            "wdp", "cur", "ecw", "iff", "lbm", "liff", "nrrd", "pam", "pcx", "pgf",
            "sgi", "rgb", "rgba", "bw", "int", "inta", "sid", "ras", "sun", "tga",
            "3g2", "3gp", "aaf", "asf", "avchd", "avi", "drc", "flv", "m2v", "m3u8",
            "m4p", "m4v", "mkv", "mng", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg",
            "mpv", "mxf", "nsv", "ogg", "ogv", "qt", "rm", "rmvb", "roq", "svi",
            "vob", "webm", "wmv", "yuv", "aac", "aiff", "ape", "au", "flac", "gsm",
            "it", "m3u", "m4a", "mid", "mod", "mp3", "mpa", "pls", "ra", "s3m",
            "sid", "wav", "wma", "xm"
        ]

        if(extensionFiles.indexOf(data.file.extension) >= 0){
            medias.files.push(data)
        } else {
            medias.media.push(data)
        }
        context.commit('SET_MEDIAS_CHAT', medias)
    },
    mediasInChat(context,data){
        context.commit('SET_MEDIAS_CHAT', data)
    },
    removeChat(context,data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        SocketAPI.deletedChat(data)
        context.commit("SET_LOADING",false);
    },
    sendChat(context,msg){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        let user_id = context.rootGetters["user/id"]
        let workgroup = context.rootGetters["workgroup/workgroup"]
        SocketAPI.send(user_id,workgroup._id,msg)
        context.commit("SET_LOADING",false);
    },
    sortingChat(context,data){
        let medias = context.getters["filesChat"]
        let workgroup = context.rootGetters["workgroup/workgroup"]
        let workgroupList = context.rootGetters["workgroup/workgroups"]
        let currentState = context.rootGetters['state/state']
        if (currentState != 'storage'){
            let chatUser = workgroup.participant
            if(chatUser.length == 0) {
                let fullname = context.rootGetters["user/fullname"]
                data.name = fullname
                // context.commit("SET_USER_CHAT",data)
            } else {
                var indexUser = chatUser.findIndex((v) => {
                    return v._id == data.user
                })
                if(indexUser >= 0){
                    data.name = chatUser[indexUser].firts_name + ' ' + chatUser[indexUser].lastname
                }
            }
        }

        data.date = moment(data.time * 1000).format("DD-MM-YYYY")
        
        var indexGroup = workgroupList.findIndex((v) => {
            return v._id == data.group
        })
        data.group_name = workgroupList[indexGroup].title
        
        if(workgroup){
            if(workgroup._id == data.group){
                let arrLink = []
                var msgLink = data.message.split(' ')
                var validateRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
                msgLink = msgLink.map(el => {
                    if(validateRegex.test(el)){
                    // if(el.includes("http")){
                        var newMsg = {
                            deleted: data.deleted,
                            file: data.file,
                            group: data.group,
                            id_chat: data.id_chat,
                            link: el,
                            message: data.message,
                            time: data.time,
                            user: data.user
                        }
                        arrLink.push(newMsg)
                    }
                    return el
                })
                
                if(arrLink.length > 0){
                    medias.links = medias.links.concat(arrLink)
                    var newArrLinks = []
                    medias.links.forEach(el1 => {
                        let index = newArrLinks.findIndex((x)=> x.id_chat === el1.id_chat && x.time === el1.time && x.time === el1.time && x.message === el1.message && x.link === el1.link)
                        if(index == -1){
                            newArrLinks.push(el1)
                        }
                    });
                    medias.links = newArrLinks
                    context.commit('SET_MEDIAS_CHAT', medias)
                }

                context.commit("SET_USER_CHAT",data)
            } else {
                // context.commit('SET_NOTIF', data)
                // context.dispatch('setNotificationChat', data)
            }
        } else{
            // context.commit('SET_NOTIF', data)
            // context.dispatch('setNotificationChat', data)
        }
    },
    setDeletedChats(context, data){
        let workgroupId = context.rootGetters['workgroup/workgroup'] ? context.rootGetters['workgroup/workgroup']._id : ''
        let chats = context.getters["userMessage"]
        var medias = context.getters["filesChat"]

        chats = chats.filter((v) => {
          return v.group == workgroupId
        })

        for (let index = 0; index < data.datafile.length; index++) {
            chats = chats.map(el => {
                if(el.id_chat == data.datafile[index].id_chat){
                    el.message = data.datafile[index].message
                    el.deleted = data.datafile[index].deleted
                }
                return el
            })

            medias.links = medias.links.filter(el => el.id_chat != data.datafile[index].id_chat)
            medias.files = medias.files.filter(el => el.id_chat != data.datafile[index].id_chat)
            medias.media = medias.media.filter(el => el.id_chat != data.datafile[index].id_chat)
        }
        // context.dispatch('isConnectedSocket')
        // .then((res)=>{
        //     if(res == false){
        //         context.dispatch('user/getSocket', {root: true})
        //         .then(() => {
        //             context.dispatch('openChat')
        //         })
        //     }
        // })
        context.commit("RESET_CHAT", chats)
    },
    setDeletedChat(context, data){
        let workgroupId = context.rootGetters['workgroup/workgroup'] ? context.rootGetters['workgroup/workgroup']._id : ''
        let chats = context.getters["userMessage"]
        var medias = context.getters["filesChat"]

        chats = chats.filter((v) => {
          return v.group == workgroupId
        })
        
        var indexChat = chats.findIndex((x) => {
            return x.group == data.group && x.id_chat == data.id_chat
        })
        var extensionFiles = [
            "doc", "docm", "docx", "dot", "dotm", "dotx", "epub",
            "fodt", "fb2", "htm", "html", "mht", "odt", "ott",
            "pdf", "rtf", "txt", "djvu", "xml", "xps", "csv",
            "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt",
            "xltm", "xltx", "fodp", "odp", "otp", "pot", "potm",
            "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx"
        ]
        var extensionMedia = [
            "ase", "art", "bmp", "blp", "cd5", "cit", "cpt", "cr2", "cut", "dds",
            "dib", "djvu", "egt", "exif", "gif", "gpl", "grf", "icns", "ico", "iff",
            "jng", "jpeg", "jpg", "jfif", "jp2", "jps", "lbm", "max", "miff", "mng",
            "msp", "nitf", "ota", "pbm", "pc1", "pc2", "pc3", "pcf", "pcx", "pdn",
            "pgm", "PI1", "PI2", "PI3", "pict", "pct", "pnm", "pns", "ppm", "psb",
            "psd", "pdd", "psp", "px", "pxm", "pxr", "qfx", "raw", "rle", "sct",
            "sgi", "rgb", "int", "bw", "tga", "tiff", "tif", "vtf", "xbm", "xcf",
            "xpm", "3dv", "amf", "ai", "awg", "cgm", "cdr", "cmx", "dxf", "e2d",
            "egt", "eps", "fs", "gbr", "odg", "svg", "stl", "vrml", "x3d", "sxd",
            "v2d", "vnd", "wmf", "emf", "art", "xar", "png", "webp", "jxr", "hdp",
            "wdp", "cur", "ecw", "iff", "lbm", "liff", "nrrd", "pam", "pcx", "pgf",
            "sgi", "rgb", "rgba", "bw", "int", "inta", "sid", "ras", "sun", "tga",
            "3g2", "3gp", "aaf", "asf", "avchd", "avi", "drc", "flv", "m2v", "m3u8",
            "m4p", "m4v", "mkv", "mng", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg",
            "mpv", "mxf", "nsv", "ogg", "ogv", "qt", "rm", "rmvb", "roq", "svi",
            "vob", "webm", "wmv", "yuv", "aac", "aiff", "ape", "au", "flac", "gsm",
            "it", "m3u", "m4a", "mid", "mod", "mp3", "mpa", "pls", "ra", "s3m",
            "sid", "wav", "wma", "xm"
        ]
        
        if(indexChat >= 0){
            chats[indexChat].deleted = data.data_chat.deleted
            chats[indexChat].message = data.data_chat.message
            chats[indexChat].link ? chats[indexChat].link = null : ''
            if(chats[indexChat].file != null){
                if(extensionFiles.indexOf(chats[indexChat].file.extension) >= 0){
                    medias.files = medias.files.filter(el => el.id_chat != chats[indexChat].id_chat)
                } else if(extensionMedia.indexOf(chats[indexChat].file.extension) >= 0){
                    medias.media = medias.media.filter(el => el.id_chat != chats[indexChat].id_chat)
                }
            }
            if(medias.links != null){
                medias.links = medias.links.filter(el => el.id_chat != chats[indexChat].id_chat)
            }
            chats[indexChat].file = data.data_chat.file
        }
        context.commit("RESET_CHAT", chats)
    },
    updateChatFile(context, data){
        let workgroupId = context.rootGetters['workgroup/workgroup'] ? context.rootGetters['workgroup/workgroup']._id : ''
        let chats = context.getters["userMessage"]
        var medias = context.getters["filesChat"]

        chats = chats.filter((v) => {
            return v.group == workgroupId
        })

        for (let index = 0; index < data.datafile.length; index++) {
            chats = chats.map(el => {
                if(el.id_chat == data.datafile[index].id_chat){
                    el.file.file_name = data.datafile[index].file.file_name
                    el.file.encrypt = data.datafile[index].file.encrypt
                }
                return el
            })

            medias.files = medias.files.map(el2 => {
                if(el2.id_chat == data.datafile[index].id_chat){
                    el2.file.file_name = data.datafile[index].file.file_name
                    el2.file = data.datafile[index].file
                }
                return el2
            })

            medias.media = medias.media.map(el3 => {
                if(el3.id_chat == data.datafile[index].id_chat){
                    el3.file.file_name = data.datafile[index].file.file_name
                    el3.file = data.datafile[index].file
                }
                return el3
            })
        }
        // context.commit('SET_MEDIAS_CHAT', medias)
        context.commit("RESET_CHAT", chats)
    },
    setNotificationChat(context, data){
        var notifMsg = context.getters['notif']
        var notifAllMsg = notifMsg.sort(function(a,b){return b.time - a.time})

        context.dispatch('setNotifChatGroup', notifAllMsg)
    },
    loadHistory(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        let workgroupId = context.rootGetters['workgroup/workgroup'] ? context.rootGetters['workgroup/workgroup']._id : ''
        let chats = context.getters["userMessage"]
        chats = chats.filter((v) => {
          return v.group == workgroupId
        })
        let group = context.getters['group']
        // let start = group.totalChats - (chats.length +4);
        // start = start < 0 ? 0 : start;
        let start = chats.length
        let limit = 5
        let workgroup = context.rootGetters["workgroup/workgroup"]
        SocketAPI.load(start,limit,workgroup._id)
        
        // var chats = context.getters["userMessage"]
        // chats.forEach( el => {
        //     context.commit("SET_USER_CHAT", el)
        // })
        context.commit("SET_LOADING",false);
    },
    newGroup(context,data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        SocketAPI.newGroup(data)
    },
    logout(context){
        SocketAPI.logout()
    },
    sortPerDay(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});

        var key = 'date'
        var messages = data.reduce((result, currentItem) => {
            (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
            return result;
        }, {})
        var obj = messages
        var arr = Object.keys(obj).map(function(key) {
            return obj[key];
        })
        context.commit('SET_CHAT_PER_DAY', arr)
        context.commit("SET_LOADING",false);
    },
    setNotifChatGroup(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});

        var key = 'group'
        var messages = data.reduce((result, currentItem) => {
            (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
            return result;
        }, {})
        var obj = messages
        var arr = Object.keys(obj).map(function(key) {
            return obj[key];
        })

        var newMsg = arr.map(el => {
            
                return {
                    data: el.map(el2 =>{
                        return el2
                    }),
                    group_by: el[0].group,
                    icon: "",
                    isread: false,
                    message: el[0].message,
                    time: el[0].time,
                    title: el[0].group_name,
                    userid: el[0].user
                }
        })

        newMsg.map(el => {
            context.commit("SET_CHAT_AND_ENCRYPT",el)
            return el
        })

        context.commit('SET_NOTIF_CHAT', newMsg)
        context.commit("SET_LOADING",false);
    },
    setNotifOffline(context,data){
        context.commit("SET_LOADING",true);
        context.commit("SET_CHAT_AND_ENCRYPT", [])
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});


        if(!data.length){
            if(data.group_by == null){
                // data.data.time = moment(data.data.datecreated).format()
                context.commit("SET_CHAT_AND_ENCRYPT",data)
            } else {
                data.data = [data.data]
                context.commit("SET_CHAT_AND_ENCRYPT",data)
            }
        } else{
            data.map(el => {
                if(el.group_by == null){
                    context.commit("SET_CHAT_AND_ENCRYPT",el)
                } else {
                    el.data = [el.data]
                    context.commit("SET_CHAT_AND_ENCRYPT",el)
                }
                // return el
            })
        }
    }
}

export const getters = {
    deleteMsg(state) {
        return state.deleteMsg
    },
    filesChat(state) {
        return state.filesChat
    },
    allParticipants(state) {
        return state.participants
    },
    allChat(state) {
        return state.chats
    },
    group(state){
        return state.groups
    },
    sortParticipant(state){
        return state.userChat
    },
    userMessage(state){
        return state.messageUser
    },
    historyChat(state){
        return state.histories
    },
    chatByDay(state){
        return state.chatByDay
    },
    listgroups(state){
        return state.listgroups
    },
    notif(state){
        return state.notif
    },
    notifChatGroup(state){
        return state.notifChatGroup
    },
    notifChatAndEncrypt(state){
        return state.notifChatAndEncrypt
    }
}