
import Storage from "../../api/storage";
import Notif from "./../../api/notif";
import moment from 'moment'

const storageUtils = new Storage();
const notificationAPI = new Notif();
export const state = {
    newNotifs : [],

    invitations : [],
    secures : [],
    chats : [],
    notifs : [],
}

export const mutations = {
    SET_NEW_NOTIFS(state, newValue) {
        state.newNotifs = newValue
    },

    SET_INVITATIONS(state, newValue) {
        state.invitations = newValue
    },
    SET_SECURES(state, newValue) {
        state.secures = newValue
    },
    SET_CHATS(state, newValue) {
        state.chats = newValue
    },
    SET_NOTIFS(state, newValue) {
        state.notifs = newValue
    },
}

export const getters = {
    newNotifs(state) {
        return state.newNotifs
    },

    secures(state) {
        return state.secures
    },
    chats(state) {
        return state.chats
    },
    invitations(state) {
        return state.invitations
    },
    notifs(state) {
        return state.notifs
    },
}

export const actions = {
    getAllNotification(context){
        var user_id = context.rootGetters['user/id']
        return new Promise((resolve, reject) => {
            notificationAPI.allNotification(user_id)
            .then((result)=>{
                context.dispatch('filteringNotifsAPI', result)
                resolve(result)
            })
            .catch((err)=>{
                reject(err)
            })
        })
    },

    filteringNotifsAPI(context, data){
        let secures = data.filter((v)=>v.datafile != null)
        let chats = data.filter((v)=>v.datachat != null)
        let invitations = data.filter((v)=>v.dataworkgroup != null)
        // context.commit("SET_SECURES", secures)
        // context.commit("SET_CHATS", chats)
        // context.commit("SET_INVITATIONS", invitations)

        let securesFilter = secures.map((e)=>{
            return {
                group_by : null,
                icon : storageUtils.getImage(e.datafile.extension),
                is_file : true,
                data : {
                    file_name : e.datafile.file_name,
                    id : e.datafile._id
                },
                title : e.datafile.file_name,
                message : e.title,
                time : e.time,
                is_read : e.isread,
                id : e.id
            }
        })

        var key = 'group_by'
        var chatGrouping = chats.reduce((result, currentItem) => {
            (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
            return result;
        }, {})
        var chatsFiltered = []
        for (const [key, value] of Object.entries(chatGrouping)) {
            var tmp = {
                group_by : key,
                icon : value[0].datachat.group_name.charAt(0).toUpperCase(),
                is_file : false,
                title :  `You have ${value.length} message from ${value[0].datachat.group_name}`,
                message : moment(value[0].time * 1000).fromNow(),
                time : value[0].time,
                count : value.length,
                is_read : false,
                id : value.map((e)=>e.id)
            }
            chatsFiltered.push(tmp)
        }
        let invitationsFilter = invitations.map((e)=>{
            return {
                group_by : e.group_by,
                icon : e.dataworkgroup.workgroup_name.charAt(0).toUpperCase(),
                owner_id : e.dataworkgroup.owner_id,
                workgroup_id : e.dataworkgroup.workgroup_id,
                is_file : false,
                title : e.title,
                message : moment(e.time * 1000).fromNow(),
                time : e.time,
                is_read : e.isread,
                id : e.id,
                user_id : e.userid
            }
        })

        let clearNotifs = [...chatsFiltered,...securesFilter,...invitationsFilter]
        clearNotifs = clearNotifs.sort((a,b)=>b.time-a.time)
        context.commit("SET_NOTIFS",clearNotifs)
    },
    seenNotif(context,id){
        return new Promise((resolve, reject) => {
            notificationAPI.readNotification(id)
            .then((result)=>{
                resolve(result)
            })
            .catch((err)=>{
                reject(err)
            })
        })
    },



    filteringNotifs(context,data){
        let secures = data.filter((v)=>v.group_by == null)
        let chats = data.filter((v)=>v.group_by != null && v.group_by != 'workgroup')
        let invitations = data.filter((v)=>v.group_by == 'workgroup')
        context.commit("SET_SECURES",secures)
        context.commit("SET_CHATS",chats)
        let securesFilter = secures.map((e)=>{
            return {
                group_by : null,
                icon : storageUtils.getImage(e.data.extension),
                is_file : true,
                data : {
                    parent_id : e.data.parent_id,
                    file_name : e.data.file_name,
                    id : e.data._id
                },
                title :  e.data.file_name,
                message : e.title,
                time : e.time,
                is_read : e.isread,
                id : [e.id]
            }
        })

        var key = 'group_by'
        var chatGrouping = chats.reduce((result, currentItem) => {
            (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
            return result;
        }, {})
        var chatsFiltered = []
        for (const [key, value] of Object.entries(chatGrouping)) {
            var tmp = {
                group_by : key,
                icon : value[0].data.group_name.charAt(0).toUpperCase(),
                is_file : false,
                title :  `You have ${value.length} message from ${value[0].data.group_name}`,
                message : moment(value[0].time * 1000).fromNow(),
                time : value[0].time,
                count : value.length,
                is_read : false,
                id : value.map((e)=>e.id)
            }
            chatsFiltered.push(tmp)
        }

        let invitationsFilter = invitations.map((e)=>{
            return {
                group_by : e.group_by,
                icon : e.data.workgroup_name.charAt(0).toUpperCase(),
                owner_id : e.data.owner_id,
                workgroup_id : e.data.workgroup_id,
                is_file : false,
                title : e.title,
                message : moment(e.time * 1000).fromNow(),
                time : e.time,
                is_read : e.isread,
                id : e.id
            }
        })

        let clearNotifs = [...chatsFiltered,...securesFilter,...invitationsFilter]
        clearNotifs = clearNotifs.sort((a,b)=>b.time-a.time)
        // context.commit("SET_NOTIFS",clearNotifs)
    },
    readNotif(context,data){
        var allNotif = context.getters['notifs'];
        if(data.is_file){
            allNotif = allNotif.filter((e)=>{
                return e.time != data.time
            })
        }else {
            if(data.group_by != 'workgroup' && data.group_by != 'workgroup_info'){
                allNotif = allNotif.filter((e)=>{
                    return e.group_by != data.group_by
                })
            }else if((data.group_by == 'workgroup' || data.group_by == 'workgroup_info')){
                allNotif = allNotif.filter((e) => {
                    return e.id != data.id
                })
            }
        }
        // context.dispatch("chat/setReadNotif",{user_id:0,id:data.id},{root:true});
        if(Array.isArray(data.id)){
            data.id.forEach((e)=> {
                context.dispatch("seenNotif", e)
            })
        }else {
            context.dispatch("seenNotif", data.id);
        }
        context.commit("SET_NOTIFS",allNotif)
    },
    newNotif(context,data){
        var allNotif = context.getters['notifs'];
        var secureNotif = context.getters['notifs'].filter((v)=>v.group_by == null && v.group_by == 'workgroup')
        allNotif = allNotif.filter((v)=>v.group_by != null && v.group_by != 'workgroup')
        var findIndex = allNotif.findIndex((v)=>data.group_by == v.group_by)
        if(findIndex >=0){
            allNotif[findIndex].title = `You have ${allNotif[findIndex].count + 1} message from ${data.data.group_name}`
            allNotif[findIndex].message = moment(data.time * 1000).fromNow()
            allNotif[findIndex].time = data.time
            allNotif[findIndex].id.push(data.id)
        }else{
            var key = 'group_by'
            var chatGrouping = [data].reduce((result, currentItem) => {
                (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
                return result;
            }, {})
            for (const [key, value] of Object.entries(chatGrouping)) {
                var tmp = {
                    group_by : key,
                    icon : value[0].data.group_name.charAt(0).toUpperCase(),
                    is_file : false,
                    title :  `You have ${value.length} message from ${value[0].data.group_name}`,
                    message : moment(value[0].time * 1000).fromNow(),
                    time : value[0].time,
                    count : value.length,
                    is_read : false,
                    id : value.map((e)=>e.id)
                }
                allNotif.push(tmp)
            }
        }
        let clearNotifs = [...allNotif,...secureNotif]
        clearNotifs = clearNotifs.sort((a,b)=>b.time-a.time)
        context.commit("SET_NOTIFS",clearNotifs)
    },
    notifSecure(context,data){
        var tmp = {
            group_by : null,
            icon : storageUtils.getImage(data.datafile.extension),
            is_file : true,
            data : {
                file_name : data.datafile.file_name,
                id : data.datafile._id
            },
            title :  data.datafile.file_name,
            message : data.title,
            time : data.time,
            is_read : data.isread,
            id : data.id
        }
        let notifExist = context.getters['notifs']
        let clearNotifs = [tmp,...notifExist]
        clearNotifs = clearNotifs.sort((a,b)=>b.time-a.time)
        context.commit("SET_NOTIFS",clearNotifs)
    },
    notifWgInfo(context,data){
        var tmp = {
            group_by : data.group_by,
            icon : data.dataworkgroup.workgroup_name.charAt(0).toUpperCase(),
            owner_id : data.dataworkgroup.owner_id,
            workgroup_id : data.dataworkgroup.workgroup_id,
            is_file : false,
            title : data.title,
            message : moment(data.time * 1000).fromNow(),
            time : data.time,
            is_read : data.isread,
            id : data.id,
            user_id : data.userid
        }
        let notifExist = context.getters['notifs']
        let clearNotifs = [tmp,...notifExist]
        clearNotifs = clearNotifs.sort((a,b)=>b.time-a.time)
        context.commit("SET_NOTIFS",clearNotifs)
    }
}
